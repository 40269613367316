import * as Parallax from 'parallax-js';
import simpleParallax from 'simple-parallax-js';

var mobileMenuBtnRef = document.querySelector('.menu-mob-btn');
var mobileMenuViewRef = document.querySelector('.header-mob-view-section');
function initMobileMenu() {
    mobileMenuBtnRef.addEventListener('click', e => {
        let isOpend = mobileMenuViewRef.classList.toString().indexOf('open');
        if (isOpend != -1) {
            mobileMenuBtnRef.classList.add('open')
            mobileMenuBtnRef.classList.remove('close')

            mobileMenuViewRef.classList.remove('open');
            document.body.classList.remove('body-fixed');
        } else {
            mobileMenuBtnRef.classList.remove('open')
            mobileMenuBtnRef.classList.add('close')

            mobileMenuViewRef.classList.add('open');
            document.body.classList.add('body-fixed');
        }
    })
}

function initCreatureSlider() {
    let selectorItems = document.querySelectorAll('.selector-box .selector-item');
    let slides = document.querySelectorAll('.creature-slider-box-wrapper .creature-slider-box')
    selectorItems.forEach(x => {
        x.addEventListener('click', (event) => {

            let index = [...event.currentTarget.parentElement.children].indexOf(event.currentTarget);
            let slide = slides[index];

            selectorItems.forEach(x => {
                if (x != event.currentTarget) {
                    x.classList.remove('selected');
                } else {
                    x.classList.add('selected');
                }
            })

            slides.forEach(x => {
                if (x != slide) {
                    x.style['display'] = 'none';
                } else {
                    x.style.display = ''
                }
            })
        })
    })
}

function initParallax() {
    let scene = document.querySelector('.section-bg-parallax');
    let parallaxInstance = new Parallax.default(scene);

    var images = document.querySelectorAll('.play-now-box-tree-box .tree .image');
    new simpleParallax(images, {
        overflow: true,
        delay: .6,
        transition: 'cubic-bezier(0,0,1,1)'
    });
}

function main() {
    
    let scrollContainer = document.querySelector('.road-map-section');
    scrollContainer.scrollLeft = 659;

    initMobileMenu();
    initCreatureSlider();
    initParallax();
}

main();